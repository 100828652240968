import {TableBase} from "./table_base";

class CostsTable extends TableBase {
    constructor() {
        super('costs');
    }

    setup() {
        if(!app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME]) {
            return;
        }

        try {
            this.drawCostsTables();
        } catch(err) {
            console.warn(`Failed to setup table ${this.GRIDSTACK_NAME}.`, err);
        }
    }

    update() {
        if(!app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME]) {
            return;
        }

        try {
            this.drawCostsTables();
        } catch(err) {
            console.warn(`Failed to update table ${this.GRIDSTACK_NAME}.`, err);
        }
    }

    drawCostsTables() {
        const $costs = app.DOM.gridstack.filter('#costs').find('tbody');
        this.paintTable($costs.eq(0), app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME].data.overview);
        this.paintTable($costs.eq(1), app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME].data.veh);
        this.paintTable($costs.eq(2), app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME].data.usr);
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new CostsTable();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});